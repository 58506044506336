import { capitalCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Box, Card, Container, Stack, Tooltip, Typography } from '@mui/material';
// routes
// import { PATH_AUTH } from '../../routes/paths';
import { useDispatch, useSelector } from 'react-redux';
import { checkSubdomain } from '../../redux/slices/school';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import { LoginForm, ParentLoginForm } from '../../sections/auth/login';
import Page404 from '../Page404';
import { setSchoolData } from '../../utils/SchoolHelper';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();
  const { school } = useSelector((state) => state.school);
  const [schoolData, setSchoolDataState] = useState(null);

  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');
  const dispatch = useDispatch();

  useEffect(() => {
    const url = window.location.host;
    const parts = url.split('.');
    const sub = parts[0];
    dispatch(checkSubdomain(sub));
  }, [dispatch]);

  useEffect(() => {
    if (school) {
      setSchoolData(school);
      setSchoolDataState(school);
    }
  }, [school]);


  const loadPage = () => {
    if (school.length === 0) {
      return <Page404 />;
    }
    return <Page title='Login'>
      <RootStyle>
        <HeaderStyle>
          {schoolData ?
            <Logo sx={{width:60}}/> : null}
          {/* {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to={''}>
                Register now
              </Link>
            </Typography>
          )} */}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant='h3' sx={{ px: 5, mt: 20, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <Image
              alt='login'
              src='https://minimal-kit-react.vercel.app/assets/illustrations/illustration_login.png'
            />
          </SectionStyle>
        )}

        <Container maxWidth='sm'>
          <ContentStyle>
            <Stack direction='row' alignItems='center' sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant='h4' gutterBottom>
                  Sign in to Power Campus
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
              </Box>

              <Tooltip title={capitalCase(method)} placement='right'>
                <>
                  <Image
                    disabledEffect
                    src={`https://minimal-assets-api.vercel.app/assets/icons/auth/ic_${method}.png`}
                    sx={{ width: 32, height: 32 }}
                  />
                </>
              </Tooltip>
            </Stack>

            {/* <Alert severity="info" sx={{ mb: 3 }}>
              Use Student Code : <strong>STC124</strong> / password :<strong> demo1234</strong>
            </Alert> */}

            {/* <LoginForm /> */}
            <ParentLoginForm/>

            {/* {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                  Get started
                </Link>
              </Typography>
            )} */}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>;


  };

  return school ? loadPage() : null;
}
