import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { List, Collapse } from '@mui/material';
//
import { NavItemRoot, NavItemSub } from './NavItem';
import { getActive } from '..';

// ----------------------------------------------------------------------

NavListRoot.propTypes = {
  isCollapse: PropTypes.bool,
  list: PropTypes.object,
  
  
};

export function NavListRoot({ list, isCollapse }) {
  const { pathname } = useLocation();
  const [activeItem,setActiveItem] = useState('');

  useEffect(()=> {
    setActiveItem(pathname);
  },[pathname]);

  // const active = getActive(list.path, pathname);
   const active = getActive(list.path, activeItem);
   const [open, setOpen] = useState(active);
  

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemRoot item={list} isCollapse={isCollapse} active={active} open={open} activeItem={activeItem} setActiveItem={setActiveItem} onOpen={() => setOpen(!open)} />

        {!isCollapse && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {(list.children || []).map((item) => (
                <NavListSub key={item.title} list={item} activeItem = {activeItem} setActiveItem ={setActiveItem}/>
              ))}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return <NavItemRoot item={list} active={active} isCollapse={isCollapse} activeItem={activeItem} setActiveItem= {setActiveItem} />;
}

// ----------------------------------------------------------------------

NavListSub.propTypes = {
  list: PropTypes.object,
  activeItem: PropTypes.string,
  setActiveItem: PropTypes.func.isRequired,
 
};

function NavListSub({ list,activeItem,setActiveItem }) {
  // const { pathname } = useLocation();

  // const active = getActive(list.path, pathname);
  const active = getActive(list.path,activeItem);


  const [open, setOpen] = useState(() => active);

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemSub item={list} onOpen={() => setOpen(!open)} open={open} active={active} activeItem = {activeItem} setActiveItem = {setActiveItem} />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {(list.children || []).map((item) => (
              <NavItemSub key={item.title} item={item} active={getActive(item.path, activeItem)} activeItem ={activeItem} setActiveItem = {setActiveItem}/>
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemSub item={list} active={active} activeItem={activeItem} setActiveItem={setActiveItem}/>;
}
