import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment, Typography} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import CommonDialog from '../../../utils/CommonDialog';

// ----------------------------------------------------------------------

export default function ParentLoginForm() {
  const { parentlogin } = useAuth();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogData, setDialogData] = useState({
    primaryButtonName: 'OK',
    secondaryButtonName: null,
    title: 'Alert !',
    body: 'Concern school admin to change password.',
  });

  const LoginSchema = Yup.object().shape({
    countryCode: Yup.string().required("Country code is required"),
    phone: Yup.number()
    .typeError("That doesn't look like a phone number")
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .min(10)
    .required('A phone number is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    countryCode: '+91',
    phone: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {

    try {
      const url = window.location.host;
      const parts = url.split('.');
      const sub =  ( (parts.length > 1 )) ? parts[0] : null;
      data.userType = 'parent'; // todo remove hard code data
      data.subdomain = sub;
      await parentlogin(data.countryCode,data.phone, data.password,data.subdomain,data.userType);
    }
    
    catch (error) {
      // console.error(error);
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', error);
      }
    }
  };

  // const showDialogForgotPassword = () => {
  //   setShowDialog(!showDialog);
  // };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity='error'>{errors.afterSubmit.message}</Alert>}

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField sx={{width: 200}} disabled name="countryCode" label="Country Code"/>
          <RHFTextField name="phone" label="Mobile Number"/>
        </Stack>

        <RHFTextField
          name='password'
          label='Password'
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                  <Iconify icon={showPassword ? 'eva:eye-off-fill' : 'eva:eye-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ my: 2 }}>
        <RHFCheckbox name='remember' label='Remember me' />
        <Link component={RouterLink} variant='subtitle2' to={PATH_AUTH.resetPassword} onClick={setDialogData}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size='large' type='submit' variant='contained' loading={isSubmitting}>
        Login
      </LoadingButton>
      {showDialog ? <CommonDialog dialogData={dialogData} /> : null}

      <Typography variant="body2" sx={{ mt:3 }}>
        Don’t have an account? {''}
        <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
          Register now
        </Link>
      </Typography>

    </FormProvider>
  );
}

// PATH_AUTH.resetPassword