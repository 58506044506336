// components
import SchoolIcon from '@mui/icons-material/School';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import ClassIcon from '@mui/icons-material/Class';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import TodayIcon from '@mui/icons-material/Today';
import PaymentIcon from '@mui/icons-material/Payment';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SvgIconStyle from '../../../components/SvgIconStyle';


// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  exam: getIcon('ic_exam'),
  banking: getIcon('ic_banking'),
  blog: getIcon('ic_blog'),
  booking: getIcon('ic_booking'),
  calendar: getIcon('ic_calendar'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  kanban: getIcon('ic_kanban'),
  mail: getIcon('ic_mail'),
};

const ParentSidebarConfig = [
  {
    subheader: 'MENU',
    items: [
      { title: 'Dashboard', path: '/dashboard', icon: ICONS.dashboard },
      {title:'Notification', path:'/dashboard/notification',icon: <NotificationsIcon/>},
      { title: 'School Info', path: '', icon: <SchoolIcon/> },
      { title: 'My Children', path: '/dashboard/children', icon: <FamilyRestroomIcon/> },
      { title: 'Homeworks', path: '/dashboard/homework', icon: ICONS.kanban },
      { title: 'Online Class', path: '', icon: <ClassIcon/> },
      { title: 'Online Exam', path: '', icon: <ContentPasteIcon/> },
      { title: 'Attendance Report', path: '/dashboard/attendance', icon: <HowToRegIcon/> },
      { title: 'Result', path: '', icon: <PlaylistAddCheckIcon/> },
      { title: 'Time Table', path: '', icon: <TodayIcon/> },
      { title: 'Calendar', path: '/dashboard/calendarSelectChildForm', icon: ICONS.booking },
      { title: 'Fees Payment', path: '', icon: <PaymentIcon/> },
      { title: 'Leave Request', path: '', icon: ICONS.mail },
      { title: 'Transport', path: '', icon: <DirectionsBusIcon/> },
      { title: 'Setting', path: '/dashboard/', icon: <SettingsIcon/> },
      { title: 'Logout', path: '', icon: <LogoutIcon/> },
    
    ],
  },
];

const StudentSidebarConfig = [
  {
    subheader: 'MENU',
    items: [
      { title: 'Dashboard', path: '/dashboard', icon: ICONS.dashboard },
      { title: 'Exam', path: '/dashboard/exams', icon: ICONS.exam },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       path: '/dashboard/user',
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: '/dashboard/user/four' },
  //         { title: 'Five', path: '/dashboard/user/five' },
  //         { title: 'Six', path: '/dashboard/user/six' },
  //       ],
  //     },
  //   ],
  // },
];

const userType = localStorage.getItem('userType');

const sidebarConfig = [];

const  setConfig = (userType) => {
  if(userType === 'student'){
    return(StudentSidebarConfig);
  }
  return(ParentSidebarConfig);
};

// export default (userType.role === 'student')? StudentSidebarConfig : ParentSidebarConfig;
export default setConfig(userType);