import jwtDecode from 'jwt-decode';
import { sign, verify } from 'jsonwebtoken';
//
import axios from './axios';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

//   // ----------------------------------------------------------------------

 const decoded = jwtDecode(accessToken); 
  console.log('Decoded Token:', decoded);
//   // const currentTime = Date.now() / 1000;

//   // console.log('Expiration time:', decoded.exp, 'Current time:', currentTime);
//   // return decoded.exp > currentTime;
return !!decoded.iat;
};



// const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   expiredTimer = window.setTimeout(() => {
    
//     // You can do what ever you want here, like show a notification
//   }, timeLeft);
// };



// ----------------------------------------------------------------------

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const setUserData = (user) => {
  if (user) {
    localStorage.setItem('user',  JSON.stringify(user));
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem('user');
    // delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession ,setUserData, verify, sign };
