import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import UserAccount from '../pages/dashboard/user/account';




// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },

    {
      path: '/auth/register',
      element: (
        <GuestGuard>
          <Register/>
        </GuestGuard>
      ),
    },
    
    {
      path: '/auth/reset-password',
      element:(
          <ResetPassword/>
      ),
    },
    {
      path: '/auth/new-password',
      element:(
          <NewPassword/>
      ),
    },
    // Dashboard Routes

    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/dashboard', element: <ParentDashboard /> },
        { path: '/dashboard/user/account', element: <UserAcc /> },
        { path: '/dashboard/exams', element: <ExamList /> },
        { path: '/dashboard/children', element: <Children/> },
        { path: '/dashboard/addChild', element: <AddChild/> },
        { path: '/dashboard/calendar', element: <Calendar/>},
        { path: '/dashboard/CalendarSelectChildForm', element: <CalendarSelectChildForm/>},
        { path: '/dashboard/notification', element: <Notification/>},
        { path: '/dashboard/homework', element: <HomeWork/>},
        { path: '/dashboard/homework/HomeWorkNotification', element: <HomeWorkNotification/>},
        { path: '/dashboard/attendance', element: <Attendance/>},
        { path: '/dashboard/attendance/AttendanceDetails', element: <AttendanceDetails/>},

       
      
      
       
        // { path: '/dashboard', element: <Navigate to='/dashboard/exams' replace />, index: true },
        // { path: '/dashboard/exam/:id', element: <PageOne /> },
        // { path: '/dashboard/three', element: <PageThree /> },
        {
          path: '/dashboard/user',
          children: [
            { element: <Navigate to='/dashboard/user/four' replace />, index: true },
            // { path: '/dashboard/user/four', element: <PageFour /> },
            // { path: '/dashboard/user/five', element: <PageFive /> },
            // { path: '/dashboard/user/six', element: <PageSix /> },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to='/404' replace /> },
      ],
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ]);
   /* return useRoutes([
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },

    // Dashboard Routes

    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='/dashboard/exams' replace />, index: true },
        { path: '/dashboard', element: <Navigate to='/dashboard/exams' replace />, index: true },
        { path: '/dashboard/exams', element: <ExamList /> },
        { path: '/dashboard/two', element: <PageTwo /> },
        { path: '/dashboard/three', element: <PageThree /> },
        {
          path: '/dashboard/user',
          children: [
            { element: <Navigate to='/dashboard/user/four' replace />, index: true },
            { path: '/dashboard/user/four', element: <PageFour /> },
            { path: '/dashboard/user/five', element: <ExamActivity /> },
            { path: '/dashboard/user/six', element: <PageSix /> },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to='/404' replace /> },
      ],
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ]); */
}

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
// const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
// const PageOne = Loadable(lazy(() => import('../sections/@dashboard/exam/instruction/ExamInstruction')));
// const PageTwo = Loadable(lazy(() => import('../pages/PageTwo')));
// const PageThree = Loadable(lazy(() => import('../pages/PageThree')));
// const PageFour = Loadable(lazy(() => import('../pages/PageFour')));
// const PageFive = Loadable(lazy(() => import('../sections/@dashboard/exam/onlineExam/ExamActivity')));
// const PageSix = Loadable(lazy(() => import('../pages/PageSix')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const ExamList = Loadable(lazy(() => import('../pages/dashboard/ExamList')));
const Children = Loadable(lazy(()  => import('../pages/dashboard/Children')));
const StudentDashboards = Loadable(lazy(() => import('../pages/dashboard/StudentDashboards')));
const ParentDashboard = Loadable(lazy(() => import('../pages/dashboard/ParentDashboard')))
const UserAcc = Loadable(lazy(() => import('../pages/dashboard/user/account')));
const AddChild = Loadable(lazy(() => import('../pages/dashboard/AddChild')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const CalendarSelectChildForm = Loadable(lazy(() => import('../pages/dashboard/CalendarSelectChild')));
const Notification = Loadable(lazy(() => import('../pages/notification/RecentNotification')));
const HomeWork = Loadable(lazy(() => import('../pages/homework/HomeWork')));
const HomeWorkNotification = Loadable(lazy(() => import('../pages/homework/HomeWorkNotification')));
const Attendance = Loadable(lazy(() => import('../pages/attendance/Attendance')));
const AttendanceDetails = Loadable(lazy(() => import('../pages/attendance/AttendanceDetails')));



