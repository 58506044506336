// routes
import { MathJaxContext } from 'better-react-mathjax';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import ThemeColorPresets from './components/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

// ----------------------------------------------------------------------

export default function App() {
  const config = {
    /* in theory, the MathML input processor should be activated if we add
    an "mml" block to the config OR if "input/mml" (NOT "input/mathml" as stated
    in the docs) is in the load array. However, this is not necessary as MathML is
    ALWAYS enabled in MathJax */
    loader: { load: ["input/mml", "output/chtml"] },
    mml: {}
  };
  return (
    <MathJaxContext version={3} config={config} >
    <ThemeProvider>
      <ThemeColorPresets>
        <RtlLayout>
          <MotionLazyContainer>
            <ProgressBarStyle />
            <Settings />
            <ScrollToTop />
            <Router />
          </MotionLazyContainer>
        </RtlLayout>
      </ThemeColorPresets>
    </ThemeProvider>
    </MathJaxContext>
  );
}
