/* eslint-disable camelcase */
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession, setUserData } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  PARENTLOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  parentlogin: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  getOtpRequest: () => Promise.resolve(),
  resetPasswordOtpRequest: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  addChild: () => Promise.resolve(),
  removeChild: ()=> Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  console.log('state in Auth Provider',state);
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const user = JSON.parse(window.localStorage.getItem('user'));
        
        console.log("accessToken====================",accessToken);
        if (accessToken && isValidToken(accessToken)) {
          console.log('Valid token found:', accessToken);
          console.log('Initializing User Object:', user);
          setSession(accessToken);
          // const response = await axios.get('/api/account/my-account');
          // const { user } = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (student_code, password, subdomain, user_type) => {
    const response = await axios.post('/api/auth/ext/login', {
      student_code,
      password,
      subdomain,
      user_type,
    });

    // const { data: { student } } = response.data;
    // const { data } = response;
    // const user = data;

    const {data: user} = response;
    
    console.log('Login User Object:', user); 
    localStorage.setItem('userType',user.role);

    // TODO: Need to clean/restructure Login API response DATA to achieve a unified repponse for all the user types.

    const { access_token: accessToken } = response.data.meta;

    setSession(accessToken);
    setUserData(user);
    localStorage.setItem('accessToken',accessToken);
    localStorage.setItem('user',JSON.stringify(user));

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const parentlogin = async (countryCode, phone, password, subdomain,user_type) => {
    const response = await axios.post('/api/auth/ext/login', {
      country_code: `${countryCode.slice(1,3)}`,
      phone,
      password,
      subdomain,
      user_type,
  
        
    });

    // const { data: { student } } = response.data;
    const user = response.data;
   
    console.log('Parent Login User Object:', user);
    localStorage.setItem('userType',user.data.role);

    const  access_token  = response.data.meta.access_token;

    setSession(access_token);
    setUserData(user);
    localStorage.setItem('accessToken',access_token);
    localStorage.setItem('user',JSON.stringify(user));

    dispatch({
      type: 'PARENTLOGIN',
      payload: {
        user,
      },
    });
  };

  const register = async (name, countryCode, phone, password, otp) => {
    return axios.post('/api/auth/ext/parent/register?isMobile=0',{
      name,
      country_code: `${countryCode.slice(1,3)}`,
      phone,
      password,
      logged_in_platform: 1,
      relation_with_student: 1,
      otp
    });
    
    // const { accessToken, user } = response.data;

    // window.localStorage.setItem('accessToken', accessToken);
    // dispatch({
    //   type: 'REGISTER',
    //   payload: {
    //     user,
    //   },
    // });
  };

  const getOtpRequest = async (code,phone) => {
    return axios.get(`/api/auth/ext/parent/register/sendotp?country_code=${code.slice(1,3)}&phone=${phone}&isMobile=0`, {})
  };

  const resetPasswordOtpRequest = async (code,phone) => {
    return axios.get(`/api/auth/ext/reset_password/sendotp?country_code=${code.slice(1,3)}&phone=${phone}`, {})
  };

  const resetPassword = async (countryCode,phone,password,otp) => {

    return axios.post(`/api/auth/ext/reset_password`,{
      country_code: `${countryCode.slice(1,3)}`,
      phone,
      password,
      otp,
      user_type: "parent", // need to change
    });
  };

  const addChild = async (school_code,student_code) => {
    
    console.log('school_code:',school_code)
    return axios.post(`/api/parent/child?isMobile=0`,{
      school_code,
      student_code,
    });
  };

  const removeChild = async (id) => {
    return axios.delete(`/api/parent/child/${id}?isMobile=0`,{});
  };

  const logout = async () => {
    setUserData(null);
    setSession(null);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    localStorage.removeItem('userType');
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        parentlogin,
        logout,
        register,
        getOtpRequest,
        resetPasswordOtpRequest,
        resetPassword,
        addChild,
        removeChild,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
