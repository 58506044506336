import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
// import mailReducer from './slices/mail';
// import chatReducer from './slices/chat';
// import productReducer from './slices/product';
import attendanceReducer from './slices/attendence';
import calendarReducer from './slices/calendar';
import schoolReducer from './slices/school';
import examsReducer from './slices/exams';
import childrenReducer from './slices/children';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};


const rootReducer = combineReducers({
  exams: examsReducer,
  school: schoolReducer,
  children: childrenReducer,
  // chat: chatReducer,
  calendar: calendarReducer,
  attendance: attendanceReducer,
  // kanban: kanbanReducer,
  // product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
