import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

// const accessToken = window.localStorage.getItem('accessToken');

// axiosInstance.interceptors.request.use((config) => {
//   config.headers.Authorization = `Bearer ${accessToken}`;
// });

axiosInstance.interceptors.request.use(
  (config) => {
    
    const accessToken = window.localStorage.getItem('accessToken');
    
    if (accessToken) {
      // Add the token to the Authorization header
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);


axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
