// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path("", '/'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    show: path(ROOTS_DASHBOARD, '/')
    // ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    // analytics: path(ROOTS_DASHBOARD, '/analytics'),
    // banking: path(ROOTS_DASHBOARD, '/banking'),
    // booking: path(ROOTS_DASHBOARD, '/booking')
  },
  exam:{
    // show: path(ROOTS_DASHBOARD, '/exam'),
    // examList: path(ROOTS_DASHBOARD,'/'),
    show: path(ROOTS_DASHBOARD, '/'),
  },
  user: {
  // root: path(ROOTS_DASHBOARD, '/user'),
  // new: path(ROOTS_DASHBOARD, '/user/new'),
  // list: path(ROOTS_DASHBOARD, '/user/list'),
  // cards: path(ROOTS_DASHBOARD, '/user/cards'),
  // profile: path(ROOTS_DASHBOARD, '/user/profile'),
  account: path(ROOTS_DASHBOARD, '/user/account'),
  // edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
  // demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  children:{
    add: path(ROOTS_DASHBOARD, '/addChild'),
    show: path(ROOTS_DASHBOARD,'/')
  }
};

