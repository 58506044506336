import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography, Avatar } from '@mui/material';
import { capitalize } from 'lodash';
import useAuth from '../../../hooks/useAuth';
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const { user } = useAuth();
  // console.log('useAuth: ', user);

  const firstName = capitalize(user?.data.first_name);
  const lastName = capitalize(user?.data.last_name);

  return (
    <Link underline='none' color='inherit' component={RouterLink} to={PATH_DASHBOARD.user.account}>
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        {/* <Avatar src="https://minimal-assets-api.vercel.app/assets/images/avatars/avatar_5.jpg" alt="Rayan Moran" /> */}
        <Avatar src={(user.data.role === 'student')? user.data.student.photo : user.data.registered_parent.photo} alt='Profile photo' />

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant='subtitle2' noWrap sx={{ width: '100%' }}>
            {firstName} {lastName}
          </Typography>
          <Typography variant='body2' noWrap sx={{ color: 'text.secondary' }}>
          {(user.data.role === 'student')? user.data.student.student_code : user.data.phone}
          </Typography>
        </Box>
      </RootStyle>
    </Link>
  );
}
