import * as Yup from 'yup';
import { useSnackbar } from 'notistack'; // can't use
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { Stack, Card, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import useAuth from '../../../../hooks/useAuth';

import axios from '../../../../utils/axios';

// ----------------------------------------------------------------------

export default function AccountChangePassword() {
  // const { enqueueSnackbar } = useSnackbar(); TODO: Need to be fixed


  // For validation
  const ChangePassWordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old Password is required'),
    newPassword: Yup.string().min(6, 'Password must be at least 6 characters').required('New Password is required'),
    confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  });

  // dafault values
  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(ChangePassWordSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // const accessToken = window.localStorage.getItem('accessToken');
  const useAuthData = useAuth()


  async function pwChange(data) {

    const url = window.location.host;
    const parts = url.split('.');
    const subDomain = parts[0];

    // const res = await fetch('https://api.neoskool.xyz/api/auth/ext/pwdchg',{
    //   method: 'POST',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${accessToken}`,
    //   },
    //   body: JSON.stringify({
    //     "currPassword": data.oldPassword,
    //     "newPassword": data.newPassword,
    //     "subdomain": subDomain,
    //     "user_type": useAuthData.user.role,
    //   }),
    // });

    const response = await axios.post('api/auth/ext/pwdchg', {
      currPassword: data.oldPassword,
      newPassword: data.newPassword,
      subdomain: subDomain,
      user_type: useAuthData.user.role,
    });
    console.log(response.data.message);
  }

  const onSubmit = async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve(pwChange(data)), 500));
      // console.log('Changed Successful')
      reset();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Card sx={{ p: 3 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end">
          
        <RHFTextField name="oldPassword" type="password" label="Old Password" />

        <RHFTextField name="newPassword" type="password" label="New Password" />

        <RHFTextField name="confirmNewPassword" type="password" label="Confirm New Password" />

        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          Save Changes
        </LoadingButton>

        </Stack>
      </FormProvider>
    </Card>
  );
}
