import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
    isLoading: false,
    error: null,
    attendanceEvents: [], // Attendance-specific event
    selectedEventId: null,
    selectedRange: null,
  };

  const slice = createSlice({
    name: 'attendance',
    initialState,
    reducers: {  
      startLoading(state) {
        state.isLoading = true;
      },

      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      }, 
      
      // GET ATTENDANCE EVENTS
    getAttendanceSuccess(state, action) {
        state.isLoading = false;
        state.attendanceEvents = action.payload;
      },
     // SELECT RANGE
     selectAttendanceRange(state, action) {
        const { start, end } = action.payload;
        state.selectedRange = { start, end };
      },
    
  
    }
  });

  export default slice.reducer;

  // Actions
  export const { selectAttendanceRange } = slice.actions;

  function reStructureAttendance({
    date,
    status,
    reason,
    
  }) {
   
    const parsedDate = new Date(date);
  
    return {
      title: status === 0 ? 'Present' : 'Absent', 
      start: parsedDate.toISOString().split('T')[0], 
      description: reason || 'No reason provided',
      backgroundColor: status === 0 ? '#28a745' : '#dc3545',
    };
  }
  
  
  
  export function getAttendance(studentId, enrollmentId, month) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(
          `/api/parent/student/attendance/${enrollmentId}?student_id=${studentId}&month=${month}`
        );
      
        console.log('API Response:', response);

        const attendanceData = response?.data?.data?.attendanceData || [];
        console.log('API Response Month:', response?.data?.data?.month);
       
        console.log('Attendance Data:', attendanceData);
  
        if (attendanceData.length > 0) {
          const res = attendanceData.map((e) => reStructureAttendance(e));
          console.log('Restructured Event:', reStructureAttendance); 
          dispatch(slice.actions.getAttendanceSuccess(res));
        } else {
          
          dispatch(slice.actions.getAttendanceSuccess([]));
        }
      } catch (error) {
        console.error('Error fetching attendance data:', error);
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  
  

  export function selectAttendanceRangeAction(start, end) {
    return async () => {
        dispatch(slice.actions.selectAttendanceRange({ start, end }));
    };
  }
  