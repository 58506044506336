import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';


const childrenSlice = createSlice({
    name: 'children',
    initialState: {
      children: null,
    },
    reducers: {
        getChildrenSuccess(state, action) {
            state.children = action.payload;
        },
    }
});

export default childrenSlice.reducer;
export const { getChildrenSuccess } = childrenSlice.actions;

export function getChildren() {
    return async () => {
      try {
        const response = await axios.get('/api/parent/child?isMobile=0', {});
        dispatch(childrenSlice.actions.getChildrenSuccess(response.data.data));
      } catch (error) {
        console.log(error);
      }
    };
}